import FetchBaseController from "./fetch-base-controller"
import flatpickr from "flatpickr";

export default class extends FetchBaseController {
  static targets = ["office", "date", "comparison", "container"];

  initialize() {
    this.instance = this.pickr;
  }

  setDate() {
    if (this.dateTarget.value === 'custom') {
      this.instance.open();
    } else {
      this.fetchResult();
    }
  }

  setComparison() {
    this.fetchResult();
  }

  get pickr() {
    return flatpickr(this.input, {
      mode: 'range',
      positionElement: this.dateTarget,
      onChange: (selectedDates, dateStr, instance) => {
        this.addCustomOption(selectedDates);
      }
    });
  }

  addCustomOption(dates) {
    if (dates.length == 2) {
      let option = document.createElement('option');
      option.setAttribute('value', this.dataFormat(dates[0]) + ':' + this.dataFormat(dates[1]));
      option.innerText = this.displayFormat(dates[0]) + ' to ' + this.displayFormat(dates[1]);
      this.dateTarget.insertBefore(option, this.customOption);
      option.selected = true;
      this.fetchResult();
    }
  }

  fetchResult() {
    fetch(this.data.get('url') + this.query, {headers: this.fetch_headers}).then(this.fetch_response)
      .then(html => {
        this.containerTarget.innerHTML = html;
      });
    history.pushState({'query':this.query}, '', this.data.get('url') + this.query)
  }

  get query() {
    let query = ''
    if (this.dateTarget.value.indexOf(':') > -1) {
      let dates = this.dateTarget.value.split(':')
      query = '?period=custom&start_date=' + dates[0] + '&end_date=' + dates[1];
    }
    else {
      query = '?period=' + this.dateTarget.value;
    }
    query = query + '&compare=' + this.comparisonTarget.value;
    if (this.officeTarget.value !== '') {
      query = query + '&office_id=' + this.officeTarget.value;
    }
    return query;
  }

  get input() {
    return document.createElement('input');
  }

  dataFormat(date) {
    return this.instance.formatDate(date, 'Y-m-d');
  }

  displayFormat(date) {
    return this.instance.formatDate(date, 'M d, Y');
  }

  get customOption() {
    let custom = null;
    Array.from(this.dateTarget.children).forEach(option => {
      if (option.value === 'custom') {
        custom = option
      }
    });
    return custom;
  }

}