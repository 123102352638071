import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  initialize() {
    this.page = parseInt(this.data.get('page'));
    this.setPaging(25);
    this.observer = null;
    this.stopped = false;

    let el = this.element;
    //const pager = this.application.getControllerForElementAndIdentifier(this.element, 'endless-page');

    //listen for dashboard reset
    document.addEventListener('pager.reset', event => {
      this.reset();
      //let pager = this.application.getControllerForElementAndIdentifier(el, 'endless-page');
      //pager.reset();
    });

    document.addEventListener('pager.stop', event => {
      this.stop();
      //let pager = this.application.getControllerForElementAndIdentifier(el, 'endless-page');
      //pager.stop();
    });
  }

  reset() {
    this.stopped = false;
    this.page = 1;
    this.setPaging(25);
  }

  stop() {
    if (this.stopped) {
      return true;
    }
    this.stopped = true;
    let sentinel = document.getElementById('page-sentinel');
    if (this.observer != null) {
      this.observer.unobserve(sentinel);
    }
    sentinel.remove();
  }

  setPaging(count) {
    if (this.stopped) {
      return;
    }
    //set the sentinel
    let sentinel = document.getElementById('page-sentinel');
    if (sentinel == null) {
      //console.log('creating page sentinel');
      sentinel = document.createElement("DIV");
      sentinel.classList.toggle('page-sentinel', true);
      sentinel.setAttribute("id", "page-sentinel");
    }
    else {
      if (this.observer) {
        this.observer.unobserve(sentinel);
      }
    }
    let children = this.containerTarget.children;
    this.containerTarget.insertBefore(sentinel, children[count]);

    if (!this.observer) {
      this.observer = new IntersectionObserver((entries, observer) => {
        let entry = entries[0];
        if (entry.intersectionRatio > 0) {
          //console.log('is visible and vertical position is: ' + entry.boundingClientRect.y + ' and old position is: ' + this.verticalPosition);
        }
        if (entry.intersectionRatio > 0 && entry.boundingClientRect.y < this.verticalPosition) {
          this.loadNextPage();
        }
        this.verticalPosition = entry.boundingClientRect.y;
        //console.log('setting vertical position to: ' + this.verticalPosition);
      });
    }
    this.observer.observe(sentinel);
  }

  fetch_response(response) {
    if (response.status == 204) {
      document.dispatchEvent(new CustomEvent('pager.stop'));
      return response.text();
    }
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.text();
  }

  loadNextPage() {
    this.page = this.page + 1;
    this.getPage(this.page);
  }

  getPage(page) {
    fetch(this.data.get('url') + '?page=' + page, {headers:this.fetch_headers}).then(this.fetch_response)
      .then(html => {
        let wrapper = document.createElement("DIV");
        wrapper.innerHTML = html;
        wrapper.childNodes.forEach((node, index) => {
          this.containerTarget.appendChild(node);
        });
        //this.containerTarget.appendChild(wrapper);
        //$(this.containerTarget).append($(html));
        this.setPaging((50 * page) - 25)
      });
  }

  get fetch_headers() {
    return {
      'Accept': 'application/json, text/html',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    };
  }
}