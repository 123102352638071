import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["panel", "label", "require"];

  toggle(event) {
    let value = this.getValue(event.currentTarget);
    console.log(value);
    this.togglePanels(value);
    this.toggleRequires(value);
    if (this.hasLabelTarget) {
      this.labelTarget.classList.toggle('open');
    }
  }

  togglePanels(value) {
    this.panelTargets.forEach(panel => {
      if ((value != null) && (panel.dataset.toggleOn != null)) {
        if (this.shouldToggle(value, panel.dataset.toggleOn)) {
          this.openPanel(panel);
        }
        else {
          this.closePanel(panel);
        }
      }
      else {
        this.togglePanel(panel);
      }
    });
  }

  toggleRequires(value) {
    this.requireTargets.forEach(input => {
      if (value == null) {
        input.required = false;
      } else {
        input.required = this.shouldToggle(value, input.dataset.requireOn);
      }
    })
  }

  openPanel(panel) {
    panel.classList.toggle('open', true);
  }

  closePanel(panel) {
    panel.classList.toggle('open', false);
  }

  togglePanel(panel) {
    panel.classList.toggle('open');
  }

  shouldToggle(value, toggleOn) {
    let tokens = toggleOn.split(',');
    return tokens.indexOf(value) > -1;
  }

  getValue(element) {
    let value = element.value || element.dataset.value;
    if (element.type && element.type === 'checkbox') {
      if (!element.checked) {
        return '0';
      }
    }
    return value;
  }
}