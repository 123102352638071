import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["mapping", "row", "errorMessage", "form", "mappingsInput", "skipInput"];

  initialize() {
    this.mappingTargets.forEach(element => {
      if (element.value == 'hidden') {
        this.toggleHidden(element.dataset.column, true)
      }
    });

  }

  toggleHidden(column, hide) {
    let elements = document.querySelectorAll('.column_' + column);
    elements.forEach(element => {
      element.classList.toggle('hidden', hide);
    });
  }

  toggleShowHidden(event) {
    this.element.classList.toggle('show-hidden');
  }

  processMapping(event) {
    let element = event.currentTarget;
    this.toggleHidden(element.dataset.column, (element.value == 'hidden'));
    element.classList.toggle('has-error', element.value == '');
  }

  save() {
    if (!this.is_valid) {
      return false;
    }
    let mapping = {};
    this.mappingTargets.forEach(element => {
      if (element.value !== 'hidden') {
        mapping[element.dataset.column] = element.value;
      }
    });
    let skippedRows = [];
    this.rowTargets.forEach(row => {
      if (!row.checked) {
        skippedRows.push(row.value);
      }
    });
    if (!confirm('Import ' + (this.rowTargets.length - skippedRows.length) + ' users with these mappings?')) {
      return false;
    }
    this.mappingsInputTarget.value = JSON.stringify(mapping);
    this.skipInputTarget.value = JSON.stringify(skippedRows);
    this.formTarget.submit();
  }

  showError(message) {
    this.errorMessageTarget.innerText = message;
    this.errorMessageTarget.style.opacity = '1';
  }

  hideError() {
    this.errorMessageTarget.innerText = '';
    this.errorMessageTarget.style.opacity = '0';
  }


  get is_valid() {
    let valid = true;
    this.mappingTargets.forEach(element => {
      if (element.value == '') {
        this.showError('Please select a mapping for each column');
        element.classList.toggle('has-error', true);
        valid = false;
      }
    });
    return valid;
  }
}