import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["calendar"];

  initialize() {
    this.boundHandleMessage = this.handleMessage.bind(this);
    if (this.data.get('show') === 'true') {
      this.show();
    }
    if (this.data.get('show') === 'tab') {

    }

  }

  connect() {
    window.addEventListener('message', this.boundHandleMessage);
  }

  disconnect() {
    window.removeEventListener('message', this.boundHandleMessage);
  }

  show(event) {
    Calendly.initInlineWidget({
      url: this.url,
      parentElement: this.calendarTarget,
      prefill: {email:this.email, name:this.name, customAnswers: { a1:this.phone, a2:1 }},
      utm: {utmSource:'Concierge'}
    });
  }

  isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  handleMessage(e) {
    if (this.isCalendlyEvent(e)) {
      console.log(e.data.event);
    }
  }

  get url() {
    return this.data.get('url') + '?hide_event_type_details=1';
  }

  get email() {
    return this.data.get('email');
  }

  get name() {
    return this.data.get('name');
  }

  get phone() {
    return this.data.get('phone');
  }
}