import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["category"];

  initialize() {
    console.log('hello from categories controller');
    document.addEventListener('sortable.sorted', event => {
      this.sort(event.detail.element.dataset.id, event.detail.position);
    });
  }

  get fetch_headers() {
    return {
      'Accept': 'application/json, text/html',
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.head.querySelector("[name=csrf-token]").content
    };
  }

  fetch_response(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.text();
  }

  sort(id, position) {
    fetch(this.data.get('url') + '/' + id, {
      method: 'PUT',
      headers: this.fetch_headers,
      body: this.parameter + "[display_order_position]=" + position
    }).then(this.fetch_response)
      .then(html => {
        console.log('sorted');
      }).catch(error => console.log(error));
  }

  get parameter() {
    if (this.data.get('parameter')) {
      return this.data.get('parameter');
    }
    return 'to_do_category'
  }
}