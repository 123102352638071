import {Controller} from "stimulus";
import Chart from 'chart.js';

export default class extends Controller {
  static targets = ["change", "total", "comparisonTotal", "currentDate", "comparisonDate", "day", "graph", "comparison"];

  initialize() {
    Chart.defaults.global.animation.duration = 0;
    //this.setGraphWidth();

    this.start = parseInt(this.data.get('start'));
    this.end = parseInt(this.data.get('end'));
    this.interval = parseInt(this.data.get('interval'));
    this.renderGraph('active', 'Clients Engaged')

    this.initialValues = {current:this.totalTarget.innerText, comparison:this.comparisonTotalTarget.innerText, change:this.changeTarget.innerText, negative:this.changeTarget.classList.contains('negative')};
  }

  setGraphWidth() {
    this.graphContainerTarget.style.width = this.wrapperTarget.offsetWidth + 'px';
  }

  renderGraph() {
    this.chart = new Chart(this.graphTarget, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [{
          label: 'Current Period',
          data: this.points,
          fill: true,
          backgroundColor: "rgba(84,105,212, 0.2)",
          borderColor: "#5469d4",
          borderWidth: 1,
        },
          {
            label: 'Previous Period',
            data: this.comparisonPoints,
            fill: true,
            backgroundColor: "rgba(136,136,136, 0.2)",
            borderColor: "#888888",
            borderWidth: 1,
          }
        ]
      },
      options: {
        responsive:false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          mode: 'index',
          intersect: false,
          custom: this.tooltip.bind(this)
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          yAxes: [
            {
              display: false
            }
          ],
          xAxes: [
            {
              display: false
            }
          ]
        }
      }
    });
  }

  resetTotals() {
    this.totalTarget.innerText = this.initialValues.current;
    this.comparisonTotalTarget.innerText = this.initialValues.comparison;
    this.currentDateTarget.innerHTML = '';
    this.comparisonDateTarget.innerHTML = '';
    this.changeTarget.innerText = this.initialValues.change;
    this.changeTarget.classList.toggle('negative', this.initialValues.negative);
  }

  tooltip(model) {
    if (model.opacity === 0) {
      this.resetTotals();
    }
    else {
      let values = this.valuesFor(model.title[0])
      if (values != null) {
        this.totalTarget.innerText = values.current;
        this.comparisonTotalTarget.innerText = values.comparison;
        this.currentDateTarget.innerHTML = values.currentLabel;
        this.comparisonDateTarget.innerHTML = values.comparisonLabel;
        if (values.change < 0) {
          this.changeTarget.innerHTML = '<span>&minus;</span>' + Math.abs(values.change) + '%';
        }
        else {
          this.changeTarget.innerHTML = '<span>&plus;</span>' + values.change + '%';
        }
        this.changeTarget.classList.toggle('negative', values.change < 0);
      }
    }
  }

  valuesFor(date) {
    let values = null;
    this.dayTargets.forEach((day, index) => {
      if (day.dataset.label === date) {
        if (this.comparisonTargets[index] != null) {
          let comparison = this.comparisonTargets[index].dataset;
          values = {
            current: day.dataset.formatted,
            currentLabel: day.dataset.label,
            comparison: comparison.formatted,
            comparisonLabel: comparison.label,
            change: this.calculateDifference(day.dataset.value, comparison.value)
          };
        }
        else {
          values = {
            current: day.dataset.formatted,
            currentLabel: day.dataset.label,
            comparison: 0,
            comparisonLabel: 'No Data',
            change: this.calculateDifference(day.dataset.value, 0)
          };
        }
      }
    });
    return values;
  }

  calculateDifference(current, previous) {
    current = parseInt(current);
    previous = parseInt(previous);
    let difference = Math.abs(current - previous);
    if (difference === 0) {
      return 0;
    }
    let average = (current + previous) / 2
    let percent = ((difference / average) * 100).toFixed(1)
    if (current < previous) {
      return 0 - percent;
    }
    return percent;
  }

  setDate(event) {
    let url = window.location.href.replace(location.search, '');
    window.location.href = url + '?range=' + event.currentTarget.value;
  }

  get points() {
    let day0 = this.dayTargets[0].dataset.date;
    let date_bucket = this.start;
    let points = [];
    while (date_bucket < day0) {
      console.log('looping in zero');
      points.push(0);
      date_bucket += this.interval;
      if (date_bucket >= this.end) {
        break;
      }
    }
    this.dayTargets.forEach((day, index) => {
      points.push(day.dataset.value);
    });
    return points;
  }

  get comparisonPoints() {
    if (!this.hasComparisonTarget) {
      return [];
    }
    let day0 = this.comparisonTargets[0].dataset.date;
    let date_bucket = this.start;
    let points = [];
    while (date_bucket < day0) {
      console.log('looping in zero');
      points.push(0);
      date_bucket += this.interval;
      if (date_bucket >= this.end) {
        break;
      }
    }
    this.comparisonTargets.forEach((day, index) => {
      points.push(day.dataset.value);
    });
    return points;
  }

  get total() {
    let total = 0;
    this.dayTargets.forEach((day, index) => {
      total += parseInt(day.dataset[this.stat]);
    });
    return total;
  }

  get labels() {
    let labels = [];
    for(let i = 0; i < (this.points.length - this.dayTargets.length); i++) {
      labels.push('No Data')
    }
    this.dayTargets.forEach((day, index) => {
      labels.push(day.dataset.label);
    });
    return labels;
  }
}