import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["container", "sort"];

  initialize() {
    console.log('hello from filters controller');
    history.scrollRestoration = "manual";
    this.search_url = this.data.get('url') + '/search?q=';
    this.filter_url = '/' + this.data.get('filter');
    this.sort_url = this.filter_url + '/sort'

  }

  toggleFilterControls(event) {
    this.element.classList.toggle('filter-open');
  }

  get fetch_headers() {
    return {
      'Accept': 'application/json, text/html',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    };
  }

  fetch_response(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.text();
  }

  update(html) {
    console.log(this.containerTarget);
    document.dispatchEvent(new CustomEvent('pager.stop'));
    if (html) {
      console.log('updating container target');
      this.containerTarget.innerHTML = html;
    }
    console.log(this.containerTarget);
    console.log('html is updated');
    document.dispatchEvent(new CustomEvent('filters.filtered'));
    console.log('filtered event sent');
    console.log('reset event sent');
  }

  search(event) {
    fetch(this.search_url + event.target.value, {
      method: 'GET',
      headers: this.fetch_headers
    }).then(this.fetch_response)
      .then(html => {
        document.dispatchEvent(new CustomEvent('pager.stop'));
        this.update(html);
      });
  }

  clearSorts() {
    this.sortTargets.forEach(element => {
      element.classList.remove('active');
    });
  }

  sort(event) {
    let sort = event.target;
    if (sort.classList.contains('active')) {
      sort.classList.toggle('asc');
    }
    this.clearSorts();
    sort.classList.toggle('active', true);
    let direction = sort.classList.contains('asc') ? 'asc' : 'desc';
    var token = document.head.querySelector("[name=csrf-token]").content;
    var body = JSON.stringify({sort: sort.dataset.sort, direction: direction, authenticity_token: token});
    fetch(this.sort_url, {
      method: 'POST',
      headers: this.fetch_headers,
      body: body
    }).then(this.fetch_response)
      .then(html => {
        this.update(html);
        document.dispatchEvent(new CustomEvent('pager.reset'));
      });
  }

  /*
  sendSort(event) {
    var token = document.head.querySelector("[name=csrf-token]").content;
    var body = JSON.stringify({sort: event.target.value, authenticity_token: token});
    fetch(this.filter_url + '/sort', {method: 'POST', headers: this.fetch_headers, body: body}).then(this.fetch_response)
      .then(html => {
        this.update(html);
        document.dispatchEvent(new CustomEvent('pager.reset'));
      });
  }
   */

  /*
  sendSortDirection(event) {
    var token = document.head.querySelector("[name=csrf-token]").content;
    var body = JSON.stringify({sort_direction: event.target.value, authenticity_token: token});
    fetch(this.filter_url + '/sort_direction', {
      method: 'POST',
      headers: this.fetch_headers,
      body: body
    }).then(this.fetch_response)
      .then(html => {
        this.update(html);
        document.dispatchEvent(new CustomEvent('pager.reset'));
      });
  }
   */

  movingFilterClicked(event) {
    var token = document.head.querySelector("[name=csrf-token]").content;
    var body = JSON.stringify({value: event.target.value, authenticity_token: token});
    fetch(this.filter_url + '/moving', {method: 'POST', headers: this.fetch_headers, body: body}).then(this.fetch_response)
      .then(html => {
        this.update(html);
        document.dispatchEvent(new CustomEvent('pager.reset'));
      });
  }

  filterState(event) {
    var token = document.head.querySelector("[name=csrf-token]").content;
    var body = JSON.stringify({value: event.target.value, authenticity_token: token});
    fetch(this.filter_url + '/filter_state', {
      method: 'POST',
      headers: this.fetch_headers,
      body: body
    }).then(this.fetch_response)
      .then(html => {
        this.update(html);
        document.dispatchEvent(new CustomEvent('pager.reset'));
      });
  }

  filterTeam(event) {
    var token = document.head.querySelector("[name=csrf-token]").content;
    var body = JSON.stringify({value: event.target.value, authenticity_token: token});
    fetch(this.filter_url + '/filter_office', {
      method: 'POST',
      headers: this.fetch_headers,
      body: body
    }).then(this.fetch_response)
      .then(html => {
        this.update(html);
        document.dispatchEvent(new CustomEvent('pager.reset'));
      });
  }

  filterClicked(event) {
    var filter = event.target.value;
    if (event.target.checked) {
      this.sendFilter(filter, 'PUT');
    } else {
      this.sendFilter(filter, 'DELETE');
    }
  }

  sendFilter(filter, method) {
    var token = document.head.querySelector("[name=csrf-token]").content;
    var body = JSON.stringify({filter: filter, load:'contacts', authenticity_token: token});
    fetch(this.filter_url + '/filter', {
      method: method,
      headers: this.fetch_headers,
      body: body
    }).then(this.fetch_response)
      .then(html => {
        this.update(html);
        document.dispatchEvent(new CustomEvent('pager.reset'));
      });
  }
}