import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["button", "panel"];


  initialize() {
  }

  openMenu(event) {
    event.stopPropagation();
    this.panelTarget.classList.toggle('in', true);
    this.startCloseEventListener(this.panelTarget);
  }

  logout(event) {
    event.preventDefault();
    var token = document.head.querySelector("[name=csrf-token]").content;
    fetch('/sign_out', {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({authenticity_token: token})
    }).then(response => {
      if (response.status == 204) {
        window.location.href = '/'
      }
    });
  }

  startCloseEventListener(element) {
    console.log('in start close event listener');
    const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
    
    const outsideClickListener = event => {
      console.log('in listener');
      if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
        console.log('removing class');
        element.classList.toggle('in', false);
        removeClickListener()
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener)
    };

    document.addEventListener('click', outsideClickListener)
  }

}