import {Controller} from "stimulus";

export default class extends Controller {

  get csrf_token() {
    return document.head.querySelector("[name=csrf-token]").content;
  }

  get fetch_headers() {
    return {
      'Accept': 'application/json, text/html',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': this.csrf_token
    };
  }

  fetch_response(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.text();
  }

  fetch_response_json(response) {
    if (!response.ok) {
      if (response.headers.get('message') != null) {
        throw Error(response.headers.get('message'));
      } else {
        throw Error(response.statusText);
      }
    }
    return response.json();
  }
}