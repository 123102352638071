import {Controller} from "stimulus";
import Fetch from '../modules/fetch'
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [];

  initialize() {
    this.sortable = new Sortable(this.element, {
      sort: true,
      delay: 0,
      handle: this.handle,
      draggable: this.draggable,
      onEnd: this.sorted.bind(this)
    });
  }

  sorted(event) {
    if (this.url) {
      return this.updatePosition(event);
    }
    document.dispatchEvent(new CustomEvent('sortable.sorted', {
      detail: {
        element: event.item,
        position: event.newIndex
      }
    }));
  }

  updatePosition(event) {
    let data = {};
    data[this.data.get('parameter')] = {};
    data[this.data.get('parameter')][this.data.get('column')] = event.newIndex;
    new Fetch(this.url + '/' + event.item.dataset.id, this.positionUpdated, {method:'PUT', body:data});
  }

  positionUpdated(response) {
    if (response.status !== 200) {
      alert(response.data)
    }
  }

  get url() {
    return this.data.get('url');
  }

  get handle() {
    let handle = this.data.get('handle');
    if (handle == null) {
      handle = '.drag-handle';
    }
    return handle;
  }

  get draggable() {
    let draggable = this.data.get('draggable');
    if (draggable == null) {
      draggable = '.well';
    }
    return draggable;
  }

}