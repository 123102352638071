import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['section'];

  initialize() {
  }

  toggleSections(event) {
    this.sectionTargets.forEach(element => {
      element.classList.toggle('open', this.isInSection(event.currentTarget.value, element.dataset.section));
    });
  }

  isInSection(value, sectionString) {
    try {
      let sections = sectionString.split(',');
      return sections.indexOf(value) > -1
    }
    catch {
      return false;
    }
  }
}