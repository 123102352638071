import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["agents", "offices"];

  initialize() {
    console.log('hello from office select controller');
    if (!this.agentsTarget.value) {
      this.reloadAgents();
    }
  }

  get fetch_headers() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.head.querySelector("[name=csrf-token]").content
    };
  }

  fetch_response(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  }

  reloadAgents(event) {
    let id = this.officesTarget.value;
    fetch(this.data.get('url') + '/' + id + '/agents', {
      method: 'GET',
      headers: this.fetch_headers
    }).then(this.fetch_response)
      .then(json => {
        this.agentsTarget.innerHTML = '';
        json.forEach((agent, index) => {
          let element = document.createElement("OPTION");
          element.setAttribute("value", agent['api_id']);
          element.innerText = agent['last_name'] + ', ' + agent['first_name'];
          this.agentsTarget.appendChild(element);
        });
      }).catch(error => console.log(error));
  }

  get parameter() {
    if (this.data.get('parameter')) {
      return this.data.get('parameter');
    }
    return 'to_do_category'
  }
}