import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  initialize() {
    this.showTab()
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  openTab(event) {
    this.index = parseInt(event.currentTarget.dataset.tab);
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      tab.classList.toggle("active", index == this.index)
      panel.classList.toggle("active", index == this.index)
      panel.classList.toggle("in", index == this.index)
    })
    document.dispatchEvent(new CustomEvent('md.tab.shown'));
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }
}